import $ from 'jquery';
import './scss/style.scss';
import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css';


// Open and close menu
const header = $('.header-nav')
const openCloseMenu = $('.header-menu-btn')

openCloseMenu.click(function() {
  const has = header.hasClass('open')

  if(has) {
    $(this).parent().parent().removeClass('open')
    $(this).find('.open').show()
    $(this).find('.close').hide()
    header.removeClass('open')
  } else {
    $(this).parent().parent().addClass('open')
    $(this).find('.close').show()
    $(this).find('.open').hide()
    header.addClass('open')
  }
})

// Translate dropdown
const dropdown = $('.header-nav-translate')
const openCloseDropdown = $('.dropdown-btn')

openCloseDropdown.click(function() {
  const has = dropdown.hasClass('open')

  if(has) {
    $(this).parent().parent().parent().removeClass('open')
    dropdown.removeClass('open')
  } else {
    $(this).parent().parent().parent().addClass('open')
    dropdown.addClass('open')
  }
})

// Tabs
const tab = $('.tab-container')
const changeTab = $('.tab-btn')

changeTab.click(function() {
  const attr = $(this).attr('type')

  changeTab.removeClass('current')
  $(this).addClass('current')

  tab.removeClass('open')
  $(`#${attr}`).addClass('open')
})

// Carousel
$('.carousel').slick({
	slidesToShow: 3,
	slidesToScroll: 1,
	infinite: true,
  arrows: true,
  dots: false,
  variableWidth: true,
  prevArrow: '.prev',
  nextArrow: '.next',
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false
      }
    }
  ]
});

// Open and close map list
const mapBtn = $('.page-map .btn')
const map = $('.map iframe')

mapBtn.click(function() {
  const has = map.hasClass('open')
  const lang = $(this).attr('language')

  const list = lang == 'en' ? 'Show list' : 'Exibir lista';
  const maps = lang == 'en' ? 'Expand map' : 'Expandir mapa';

  if(has) {
    $(this).text(list)
    map.removeClass('open')
  } else {
    $(this).text(maps)
    map.addClass('open')
  }
})

// Show and hide post list items
const hideBtn = $('.hide-events')
const posts = $('.can-hide')

hideBtn.click(function() {
  const has = posts.hasClass('hide')
  const lang = $(this).attr('language')

  const show = lang == 'en' ? 'Show past events' : 'Mostrar eventos já ocorridos';
  const hide = lang == 'en' ? 'Hide events that have already occurred' : 'Ocultar eventos já ocorridos';

  if(has) {
    $(this).find('.arrow').removeClass('arrow-down')
    $(this).find('.btn-text').text(hide)
    posts.removeClass('hide')
  } else {
    $(this).find('.arrow').addClass('arrow-down')
    $(this).find('.btn-text').text(show)
    posts.addClass('hide')
  }
})

// Form textarea
const textarea = $('.form textarea')
textarea.click(function() {
  $(this).addClass('active')
})

// Gallery
const galleryItem = $('.gallery li')
const galleryClose = $('.modal .close')
let gallerySlider = $('.modal .modal-slider')
const gallerySliderItem = gallerySlider.find(`.modal-item`)

const prev = $('.modal').find('.prev')
const next = $('.modal').find('.next')
let length
let currentSlide = Number( $('.modal .current').attr('index') )

galleryItem.click(function(){
  const galleryIndex = $(this).parent().attr('index')
  const itemIndex = $(this).attr('index')

  $(`.modal-${galleryIndex}`).show()
  $(`.modal-${galleryIndex} .modal-item`).hide()
  $(`.modal-${galleryIndex} .modal-item[index='${itemIndex}']`).show()

  $(`.modal-${galleryIndex} .modal-item`).removeClass('current')
  $(`.modal-${galleryIndex} .modal-item[index='${itemIndex}']`).addClass('current')

  currentSlide = Number( itemIndex )
  gallerySlider = $(`.modal-${galleryIndex} .modal-slider`)
  length = gallerySlider.find('.modal-item').length - 1
})

galleryClose.click(function() {
  $('.modal').hide()
})

next.click(function() {
  currentSlide === length  ? currentSlide = 0 : currentSlide += 1
  gallerySliderItem.hide()
  gallerySlider.find(`.modal-item[index='${currentSlide}']`).show()
})

prev.click(function() {
  currentSlide === 0 ? currentSlide = length : currentSlide -= 1
  gallerySliderItem.hide()
  gallerySlider.find(`.modal-item[index='${currentSlide}']`).show()
})